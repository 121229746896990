import { useCallback, useState } from "react";
import { getError } from "../../utils";

const useRemoveNFTStakingContract = () => {
  const token = localStorage.getItem("token") as string;

  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const mutate = useCallback(
    async ({ address, onCompleted }) => {
      setLoading(true);

      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/contracts/removeStakingContract`,
        {
          method: "PUT",
          headers: {
            "x-auth-token": token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ address }),
        }
      );

      if (res.ok) {
        alert("Success.");
      } else {
        const message = await getError(res);
        setError(`${res.status}: ${message || res.statusText}`);
      }

      if (res.ok) {
        alert("Staking Contract Removed.");
        setData(await res.json());
        setError(undefined);
        setLoading(false);

        onCompleted?.();
      } else {
        const message = await getError(res);
        setError(`${res.status}: ${message || res.statusText}`);
        setLoading(false);
      }
    },
    [setLoading, setData, setError, token]
  );

  return { mutate, data, loading, error };
};

export { useRemoveNFTStakingContract };
