import { Button, Heading, Input, Stack } from "@chakra-ui/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Label, Layout } from "../components";
import { getError } from "../utils";

type RegisterFormData = {
  email: string;
  password: string;
};

const Register = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<RegisterFormData>();

  const [error, setError] = useState<string>();

  const onSubmit = handleSubmit(async ({ email, password }) => {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/api/login/newUser`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      }
    );

    if (res.ok) {
      alert("User registered, redirecting you to Login.");
      navigate("/login");
    } else {
      const message = await getError(res);
      setError(`${res.status}: ${message || res.statusText}`);
    }
  });

  return (
    <Layout>
      <form {...{ onSubmit }}>
        <Stack spacing={4} p={4} background="white">
          <Heading textAlign="center" fontSize="2xl">
            Register
          </Heading>

          <Stack>
            <Label>Email</Label>
            <Input
              {...register("email")}
              placeholder="Email Address"
              type="email"
            />
          </Stack>

          <Stack>
            <Label>Password</Label>
            <Input
              {...register("password")}
              placeholder="Password"
              type="password"
            />
          </Stack>

          <Button
            variant="solid"
            colorScheme="teal"
            isLoading={isSubmitting}
            type="submit"
          >
            Submit →
          </Button>

          {error && <ErrorMessage>{error}</ErrorMessage>}
        </Stack>
      </form>
    </Layout>
  );
};

export { Register };
