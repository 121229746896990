import { Stack, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Layout } from "../components";
import { clearUser } from "../state";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    clearUser();
    navigate("/login");
  });

  return (
    <Layout>
      <Stack spacing={4} p={4} background="white">
        <Text>Logging you out...</Text>
      </Stack>
    </Layout>
  );
};

export { Logout };
