import { Button, Flex, Input, Stack } from "@chakra-ui/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage, Label } from "../../components";
import { getError } from "../../utils";

type CreateNFTContractFormData = {
  address: string;
};

const CreateNFTContractForm = () => {
  const token = localStorage.getItem("token") as string;

  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<CreateNFTContractFormData>();

  const [error, setError] = useState<string>();

  const onSubmit = handleSubmit(async ({ address }) => {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/api/contracts/newNFTContract`,
      {
        method: "POST",
        headers: { "x-auth-token": token, "Content-Type": "application/json" },
        body: JSON.stringify({ address }),
      }
    );

    if (res.ok) {
      alert("Success.");
    } else {
      const message = await getError(res);
      setError(`${res.status}: ${message || res.statusText}`);
    }
  });

  return (
    <form {...{ onSubmit }}>
      <Stack spacing={4}>
        <Stack>
          <Label>Create NFT Contract</Label>
          <Flex gap={2}>
            <Input {...register("address")} placeholder="Address" type="text" />

            {watch().address && (
              <Button
                variant="solid"
                colorScheme="teal"
                isLoading={isSubmitting}
                type="submit"
                paddingX={8}
              >
                Submit →
              </Button>
            )}
          </Flex>
        </Stack>

        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Stack>
    </form>
  );
};

export { CreateNFTContractForm };
