import { Box, Button, Flex, Grid, Link, Tag } from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { getUser } from "../state";

const Header = () => {
  const user = getUser();

  return (
    <Box
      as="header"
      position="fixed"
      top={0}
      width="full"
      background="white"
      zIndex={10}
    >
      <Grid
        p={4}
        gridTemplateColumns="auto 1fr auto"
        width="full"
        alignItems="center"
      >
        <Navlink path="/">Home</Navlink>

        <div />

        {!user ? (
          <Flex gap={4}>
            <Navlink path="/login">Login</Navlink>
            <Navlink path="/register">Register</Navlink>
          </Flex>
        ) : (
          <Flex gap={4}>
            <Tag>{user.email}</Tag>
            <Link as={RouterLink} to="/logout" textAlign="center" color="teal">
              Logout
            </Link>
          </Flex>
        )}
      </Grid>
    </Box>
  );
};

export { Header };

const Navlink: React.FC<{ path: string }> = ({ children, path }) => {
  const { pathname } = useLocation();
  const active = pathname === path;
  return (
    <Button
      as={RouterLink}
      to={`${path}`}
      color={active ? "white" : "teal.500"}
      bg={active ? "teal.500" : "transparent"}
    >
      {children}
    </Button>
  );
};
