import { Text } from "@chakra-ui/react";
import React from "react";

const Label: React.FC = ({ children }) => {
  return (
    <Text fontSize="sm" as="label" fontWeight="medium" color="gray.600">
      {children}
    </Text>
  );
};

export { Label };
