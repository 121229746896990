import { CopyIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  Stack,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useClipboard,
} from "@chakra-ui/react";
import React from "react";
import { ErrorMessage } from "../../components";
import { useRemoveNFTContract } from "./useRemoveNFTContract";
import { useRemoveNFTStakingContract } from "./useRemoveNFTStakingContract";

interface ContractsListProps {
  addresses: string[];
  tab: number;
}

const ContractsList: React.FC<ContractsListProps> = ({ addresses, tab }) => {
  return (
    <Stack border="1px" borderColor="gray.100" maxHeight={600} overflow="auto">
      <Table variant="simple" size="sm">
        <TableCaption>Contracts found: {addresses?.length}</TableCaption>
        <Thead>
          <Tr>
            <Th>Addresses</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {addresses.map((address) => {
            return <TableRow key={address} {...{ address, tab }} />;
          })}
        </Tbody>
      </Table>
    </Stack>
  );
};

export { ContractsList };

const TableRow: React.FC<{ address: string; tab: number }> = ({
  address,
  tab,
}) => {
  const { hasCopied, onCopy } = useClipboard(address);

  const removeNFTContractMutation = useRemoveNFTContract();
  const removeNFTStakingContractMutation = useRemoveNFTStakingContract();

  return (
    <Tr>
      <Td fontSize="sm">
        <pre>{address}</pre>
      </Td>
      <Td>
        <Stack spacing={0}>
          <Flex gap={2}>
            <IconButton
              aria-label="Copy Address"
              icon={<CopyIcon />}
              onClick={onCopy}
            />

            <IconButton
              aria-label="Delete Contract"
              icon={<DeleteIcon />}
              onClick={() => {
                if (tab === 0) {
                  removeNFTContractMutation.mutate({
                    address,
                    onCompleted: () => {
                      window.location.reload();
                    },
                  });
                }
                if (tab === 1) {
                  removeNFTStakingContractMutation.mutate({
                    address,
                    onCompleted: () => {
                      window.location.reload();
                    },
                  });
                }
              }}
            />
          </Flex>

          <Box position={"relative"}>
            <Popover
              returnFocusOnClose={false}
              isOpen={hasCopied}
              closeOnBlur
              placement="right-end"
            >
              <PopoverContent width="fit-content">
                <PopoverBody>Copied!</PopoverBody>
              </PopoverContent>
            </Popover>

            <ErrorMessage>
              {removeNFTContractMutation.error ||
                removeNFTStakingContractMutation.error}
            </ErrorMessage>
          </Box>
        </Stack>
      </Td>
    </Tr>
  );
};
