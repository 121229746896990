import { Button, Heading, Input, Link, Stack } from "@chakra-ui/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { ErrorMessage, Label, Layout } from "../components";
import { persistUser } from "../state";
import { getError } from "../utils";

type LoginFormData = {
  email: string;
  password: string;
};

const Login = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<LoginFormData>();

  const [error, setError] = useState<string>();

  const onSubmit = handleSubmit(async ({ email, password }) => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, password }),
    });

    if (res.ok) {
      alert("User signed in, redirecting you to Dashboard.");
      const user = await res.json();
      const token = res.headers.get("x-auth-token");

      persistUser(user, token);
      navigate("/");
    } else {
      const message = await getError(res);
      setError(`${res.status}: ${message || res.statusText}`);
    }
  });

  return (
    <Layout>
      <form {...{ onSubmit }}>
        <Stack spacing={4} p={4} background="white">
          <Heading textAlign="center" fontSize="2xl">
            Login
          </Heading>

          <Stack>
            <Label>Email</Label>
            <Input
              {...register("email")}
              placeholder="Email Address"
              type="email"
            />
          </Stack>

          <Stack>
            <Label>Password</Label>
            <Input
              {...register("password")}
              placeholder="Password"
              type="password"
            />
          </Stack>

          <Button
            variant="solid"
            colorScheme="teal"
            isLoading={isSubmitting}
            type="submit"
          >
            Submit →
          </Button>

          <Link as={RouterLink} to="/register" textAlign="center" color="teal">
            Register New User
          </Link>

          {error && <ErrorMessage>{error}</ErrorMessage>}
        </Stack>
      </form>
    </Layout>
  );
};

export { Login };
