import { useCallback, useState } from "react";
import { getError } from "../../utils";

export const useContracts = () => {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const fetchContracts = useCallback(async () => {
    setLoading(true);

    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/api/contracts/all`,
      { method: "GET" }
    );

    if (res.ok) {
      setData(await res.json());
      setError(undefined);
      setLoading(false);
    } else {
      const message = await getError(res);
      setError(`${res.status}: ${message || res.statusText}`);
      setLoading(false);
    }
  }, [setLoading, setData, setError]);

  return { fetch: fetchContracts, data, loading, error };
};
