import { WarningIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Heading,
  Link,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Layout } from "../components";
import { CreateNFTContractForm } from "../features/contracts/CreateNFTContract";
import { CreateNFTStakingContractForm } from "../features/contracts/CreateNFTStakingContractForm";
import { ContractsList } from "../features/contracts/ContractsList";
import { useContracts } from "../features/contracts/useContracts";
import { getUser } from "../state";
import jwt_decode from "jwt-decode";

const Dashboard = () => {
  const user = getUser();

  return (
    <Layout>
      <Stack spacing={4} p={4} background="white">
        <Heading textAlign="center" fontSize="2xl">
          Dashboard
        </Heading>

        {user ? (
          <Content />
        ) : (
          <>
            <Text textAlign="center">
              You are not logged in.{" "}
              <Link as={RouterLink} to="/login" textAlign="center" color="teal">
                Login here.
              </Link>
            </Text>
          </>
        )}
      </Stack>
    </Layout>
  );
};

export { Dashboard };

const Content = () => {
  const token = localStorage.getItem("token");
  const { isAdmin } = jwt_decode(token!) as any;

  const [tab, setTab] = useState(0);

  const contractsQuery = useContracts();

  useEffect(() => {
    async function fetchData() {
      await contractsQuery.fetch();
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractsQuery.fetch]);

  const contracts = contractsQuery.data;

  if (!isAdmin)
    return (
      <Alert status="warning" gap={4}>
        <WarningIcon />
        <AlertTitle>Your user is not an Admin</AlertTitle>
        <AlertDescription>
          Please check the database, or{" "}
          <Link as={RouterLink} to="/logout" color="teal">
            log in again.
          </Link>
        </AlertDescription>
      </Alert>
    );

  return (
    <Stack spacing={4}>
      <Tabs
        variant="solid-rounded"
        isFitted
        colorScheme={"teal"}
        index={tab}
        onChange={(i) => {
          setTab(i);
        }}
      >
        <TabList>
          <Tab>NFT</Tab>
          <Tab>Staking</Tab>
        </TabList>

        <TabPanels>
          <TabPanel p={2} py={4}>
            <Stack spacing={8}>
              <CreateNFTContractForm />
              {contracts?.nft && (
                <ContractsList addresses={contracts.nft} {...{ tab }} />
              )}
            </Stack>
          </TabPanel>

          <TabPanel p={2} py={4}>
            <Stack spacing={8}>
              <CreateNFTStakingContractForm />
              {contracts?.staking && (
                <ContractsList addresses={contracts.staking} {...{ tab }} />
              )}
            </Stack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
};
