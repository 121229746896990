type User = {
  email: string;
};

export function persistUser(user: User, token: string | null) {
  localStorage.setItem("userEmail", user.email);
  localStorage.setItem("token", token || "");
}

export function clearUser() {
  localStorage.removeItem("userEmail");
  localStorage.removeItem("token");
}

export function getUser() {
  const userEmail = localStorage.getItem("userEmail");

  if (!userEmail) {
    return undefined;
  } else {
    return {
      email: userEmail,
    };
  }
}
