import { Grid, Stack } from "@chakra-ui/react";
import React from "react";
import { Header } from "./Header";

const Layout: React.FC = ({ children }) => {
  return (
    <Grid
      position="absolute"
      justifyContent="center"
      alignItems="center"
      width="100%"
      minHeight="100vh"
      background="gray.100"
    >
      <Header />
      <Stack p={8} spacing={4} mt={150} pt={0} width={700} maxWidth={"80vw"}>
        {children}
      </Stack>
    </Grid>
  );
};

export { Layout };
