import { Text } from "@chakra-ui/react";
import React from "react";

const ErrorMessage: React.FC = ({ children }) => {
  return (
    <Text fontSize="sm" fontWeight="medium" color="red.500">
      {children}
    </Text>
  );
};

export { ErrorMessage };
